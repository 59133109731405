import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import withRoot from '../withRoot';
import Layout from '../components/layout';
import {graphql} from 'gatsby';
import Spotlight from '../components/spotlight';
import ReleasesGrid from '../components/releasesGrid';
import AboutMe from '../components/aboutMe';
import FAQ from '../components/FAQ';

const styles = theme => ({
	root: {
		textAlign: 'center',
	},
});

class Index extends React.Component {

	render () {
		const {classes, data, themeSwitcher} = this.props;

		return (
			<Layout home themeSwitcher={themeSwitcher}>
				<div className={classes.root} itemScope itemType={"https://schema.org/MusicGroup"}>
					<meta itemProp={"genre"} content={"Electronic Dance Music"}/>
					<meta itemProp={'email'} content={'contact@felononbass.com'}/>
					<meta itemProp={"location"} content={"Russia"}/>
					<meta itemProp={"name"} content={"FelonOnBass"}/>
					<meta itemProp={'url'} content={'FelonOnBass.com'}/>
					<meta itemProp={'sameAs'} content={'https://soundcloud.com/felononbass'}/>
					<meta itemProp={'sameAs'} content={'https://www.youtube.com/channel/UCMK23fl3dUvk8AVwFDu0KEw'}/>
					<meta itemProp={'sameAs'} content={'https://felononbass.bandcamp.com'}/>
					<meta itemProp={'sameAs'} content={'https://twitter.com/FelonOnBass'}/>
					<meta itemProp={'sameAs'} content={'https://www.patreon.com/FelonOnBass'}/>


					<Spotlight release={data.spotlightAlbum.edges[ 0 ][ 'node' ]}/>
					<ReleasesGrid needButton
						releases={data.latestReleases.edges.filter(x => x.node.id !== data.spotlightAlbum.edges[ 0 ][ 'node' ][ 'id' ]).slice(0, 12)} name={'Latest music_'}/>
					<AboutMe/>
					<FAQ/>
				</div>
			</Layout>

		);
	}
}

Index.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(Index));

export const query = graphql`
  query {
  spotlightAlbum: allReleasesJson(sort: {fields: [releaseDate], order: DESC}, filter: {type: {eq: "album"}}, limit: 1) {
    edges {
      node {
        id
        name
        subName
        releaseDate
        type
        feat
        originalArtist
        license
        commerce
        annotation
        cover
        header
        youtube
        soundcloud
        bandcamp
        bandcampID
        fields {
          slug
        }
      }
    }
  }
  latestReleases: allReleasesJson(sort: {fields: [releaseDate], order: DESC}, limit: 13) {
    edges {
      node {
        id
        name
        subName
        releaseDate
        type
        feat
        originalArtist
        license
        commerce
        annotation
        cover
        header
        youtube
        soundcloud
        bandcamp
        bandcampID
        fields {
          slug
        }
      }
    }
  }
}
`;