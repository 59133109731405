import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import withRoot from '../../withRoot';

const styles = theme => ({
	root: {},
	placeHolder: {
		width: '100%',
		height: '100%',
		float: 'left',
	},
	icon: {
		maxHeight: 100,
		maxWidth: 100,
	},
});

class bandcamp extends React.Component {

	render () {
		const {classes, release} = this.props;

		return (
			<div className={classes.root}>

				<iframe style={{
					width: '100%',
					border: 0,
					height: '100%',
					minHeight: 280,
				}}
				        title={'Bandcamp embed'}
				        src={'https://bandcamp.com/EmbeddedPlayer/' + release.bandcampID + '/size=large/bgcol=ffffff/linkcol=000000/artwork=none/transparent=true/'}
				        seamless>
				</iframe>


			</div>
		);
	}
};

bandcamp.propTypes = {
	classes: PropTypes.object.isRequired,
	release: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(bandcamp));
