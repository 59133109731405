import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {Link} from 'gatsby';
import withRoot from '../withRoot';
import Cover from './cover';
import Bandcamp from './embeds/bandcamp';
import Grid from '@material-ui/core/Grid/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography/Typography';
import Button from '@material-ui/core/Button';

const styles = theme => ({
	root: {
		textAlign: 'left',
		width: '100%',
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2,
	},
	grid: {
		width: '100%',
		maxWidth: 1440,
		margin: 'auto',
	},
	btn: {
		color: theme.palette.primary.dark,
		textDecoration: 'none',
	},
});

class Spotlight extends React.Component {

	render () {
		const {classes, release} = this.props;

		return (
			<div className={classes.root}>
				<Grid container spacing={3} justify={'space-around'} className={classes.grid}>
					<Grid item container xs={12} spacing={3} justify={'center'}>
						<Grid item xs={12} sm={8} md={7}>
							<Typography variant={'h1'}>Latest album_</Typography>
						</Grid>
						<Hidden xsDown><Grid item container sm={4} md={3} justify={'flex-end'}>
							<Link to={release.fields.slug} className={classes.btn}><Button color={'inherit'}>Read
								More</Button></Link>
						</Grid></Hidden>
					</Grid>
					<Grid item container xs={12} spacing={3} justify={'center'}>
						<Grid item xs={12} sm={6} md={4}>
							{release.cover ? <Cover imgName={release.cover} release={release}/> :
								<Cover placeholder release={release}/>}
						</Grid>
						<Grid item xs={12} sm={6} md={6}>
							<Bandcamp release={release}/>
						</Grid>
					</Grid>
					<Hidden smUp><Grid item container sm={4} md={3} justify={'center'}>
						<Link to={release.fields.slug} className={classes.btn}><Button color={'inherit'}>Read
							More</Button></Link>
					</Grid></Hidden>
				</Grid>

			</div>
		);
	}
};

Spotlight.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(Spotlight));
