import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import withRoot from '../withRoot';
import Cover from './cover';
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography/Typography';
import {Link} from 'gatsby';
import Button from '@material-ui/core/Button/Button';

const styles = theme => ({
	root: {
		textAlign: 'left',
		width: '100%',
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2,
	},
	grid: {
		width: '100%',
		maxWidth: 1440,
		margin: 'auto',
	},
	btn: {
		color: theme.palette.primary.dark,
		textDecoration: 'none',
	},
	aboutText: {
		padding: 16,
		paddingBottom: 0,
	}
});

class AboutMe extends React.Component {

	render () {
		const {classes} = this.props;

		return (
			<div className={classes.root}>
				<Grid container spacing={3} justify={'space-around'} className={classes.grid}>
					<Grid item container xs={12} spacing={3} justify={'center'}>
						<Grid item xs={12} sm={12} md={10}>
							<Typography variant={'h1'}>About me_</Typography>
						</Grid>
					</Grid>
					<Grid item container xs={12} spacing={3} justify={'center'}>
						<Grid item xs={12} sm={6} md={4}>
							<Cover imgName={'Felon.png'}/>
						</Grid>
						<Grid item container xs={12} sm={6} md={6}>
							<Grid inem xs={12} className={classes.aboutText}>
								<Typography variant={'h2'}>My name’s Felix.</Typography>
								<Typography variant={'body1'}>I live in Russia. I do pony music. <p/>
									There is not much to say about me. <p/>
									Sometimes I work as DJ. <p/>
									And surprisingly – This is my site. <p/>
									Feel free to check my music and leave feedback! </Typography>
							</Grid>
							<Grid item sm={12} justify={'flex-start'}>
								<Link to={'/rodina'} className={classes.btn}><Button
									color={'inherit'}>Head to Rodina</Button></Link>
							</Grid>
						</Grid>

					</Grid>
				</Grid>

			</div>
		);
	}
};

AboutMe.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(AboutMe));
