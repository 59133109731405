import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import withRoot from '../withRoot';
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
	root: {
		textAlign: 'left',
		width: '100%',
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2,
	},
	grid: {
		width: '100%',
		maxWidth: 1440,
		margin: 'auto',
	},
	btn: {
		color: theme.palette.primary.dark,
	},
	heading: {
		flexBasis: '90%',
		flexShrink: 0,
	},
});

class FAQ extends React.Component {

	state = {
		expanded: null,
	};

	handleChange = panel => (event, expanded) => {
		this.setState({
			expanded: expanded ? panel : false,
		});
	};

	render () {
		const {classes} = this.props;
		const {expanded} = this.state;

		return (
			<div className={classes.root}>
				<Grid container spacing={3} justify={'space-around'} className={classes.grid}>
					<Grid item container xs={12} spacing={3} justify={'center'}>
						<Grid item xs={12} sm={12} md={10}>
							<Typography variant={'h1'}>FAQ_</Typography>
						</Grid>
					</Grid>
					<Grid item container xs={12} spacing={3} justify={'center'}>
						<Grid item xs={12} sm={12} md={10}>

							<ExpansionPanel elevation={0} expanded={expanded === 'panel1'} onChange={this.handleChange('panel1')}>
								<ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
									<Typography variant={'body2'} className={classes.heading}>May I use your
										music? </Typography>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails>
									<Typography variant={'body1'}>
										Well. It depends. If you want to use it at livestream as background music, in
										animation or play it as DJ — feel free to, but be sure to credit me (And other
										people who were featured in song you use). Do not be afraid to contact me for
										more info about this.
									</Typography>
								</ExpansionPanelDetails>
							</ExpansionPanel>
							<ExpansionPanel elevation={0} expanded={expanded === 'panel2'} onChange={this.handleChange('panel2')}>
								<ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
									<Typography variant={'body2'} className={classes.heading}>May I monetize videos with
										your music? </Typography>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails>
									<Typography variant={'body1'}>
										Why not? Just credit me. It is not like you selling my music or something, so
										I’m totally okay with it.
									</Typography>
								</ExpansionPanelDetails>
							</ExpansionPanel>
							<ExpansionPanel elevation={0} expanded={expanded === 'panel3'} onChange={this.handleChange('panel3')}>
								<ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
									<Typography variant={'body2'} className={classes.heading}>May I remix/cover your
										song?</Typography>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails>
									<Typography variant={'body1'}>
										Yes. You can. Just let me know, and I will help you to collect all necessary
										files to make a remix.
									</Typography>
								</ExpansionPanelDetails>
							</ExpansionPanel>
							<ExpansionPanel elevation={0} expanded={expanded === 'panel4'} onChange={this.handleChange('panel4')}>
								<ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
									<Typography variant={'body2'} className={classes.heading}>So, may I get free code or
										free copy of one of your songs?</Typography>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails>
									<Typography variant={'body1'}>
										Maybe? Sometimes we have some situations when we can't buy things, because
										we do not really have money to spend. Hit me up. Maybe I will give you discount
										or something like this.
									</Typography>
								</ExpansionPanelDetails>
							</ExpansionPanel>
							<ExpansionPanel elevation={0} expanded={expanded === 'panel5'} onChange={this.handleChange('panel5')}>
								<ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
									<Typography variant={'body2'} className={classes.heading}>I would like to collab
										with you / I have an idea for a song</Typography>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails>
									<Typography variant={'body1'}>
										Contact me.
									</Typography>
								</ExpansionPanelDetails>
							</ExpansionPanel>
							<ExpansionPanel elevation={0} expanded={expanded === 'panel6'} onChange={this.handleChange('panel6')}>
								<ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
									<Typography variant={'body2'} className={classes.heading}>I have an another question!</Typography>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails>
									<Typography variant={'body1'}>
										Contact me?
									</Typography>
								</ExpansionPanelDetails>
							</ExpansionPanel>

						</Grid>
					</Grid>
				</Grid>

			</div>
		);
	}
};

FAQ.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(FAQ));
